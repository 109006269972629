<template>
  <iframe
    :src="`/component-html/main.html`"
    width="100%"
    height="100%"
    frameborder="0"
  >
  </iframe>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
